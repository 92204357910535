h4 {
    margin-top: 7px !important;
}

.user_info_checkbox {
    font-size: 12px;
}

.user_info_btn_wrapper {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.user_info_btn_wrapper div button {
    margin-right: 10px;
}

.user_info_wrapper span {
    width: 135px ;
}

.user_info_comments_wrapper p{
    margin: 0;
}

.user_info_check_wrapper {
    max-height: 170px;
    box-sizing: border-box;
    overflow: scroll;
}

.user_info_permissions_check_wrapper {
    display: flex;
}

.search_icon {
    content: url('../../../assets/icons/search.svg');
}

.add_icon_green {
    content: url('../../../assets/icons/plus-circle_green.svg');
    margin: 0 10px 2px 0;
}

.add_icon {
    content: url('../../../assets/icons/plus-circle.svg');
    margin: 0 10px 4px 0;
    cursor: pointer;
}

.delete_icon {
    content: url('../../../assets/icons/x-circle.svg');
    margin: 0 10px 2px 10px;
    cursor: pointer;
}

.arrow_icon {
    content: url('../../../assets/icons/arrow-left-circle-fill.svg');
    margin: 0 5px 3px 0;
}

.user_info_companies_wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
}
.user_info_companies_wrapper h4{
    width: 270px;
}

.user_info_company_add {
    padding: 4px 0 4px 5px !important;
}

.error {
    margin: 0 , 0 5px, 0;
    color: red;
}

.saving_notification {
    margin-top: 10px;
    color: #6C757D;
}

.question_icon {
    content: url('../../../assets/icons/question-circle.svg');
    margin: 3px 0 0px 5px;
}