.modal_root {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    z-index: 1000;
}

.modal_dialog {
    margin: 3.5rem auto !important;
}

.modal_content {
    position: relative;
    display: flex;
    max-height: 100%;
    overflow: hidden;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: var(--cui-modal-content-bg, #fff);
    background-clip: padding-box;
    border: 1px solid var(--cui-modal-content-border-color, rgba(0, 0, 21, 0.2));
    border-radius: 0.3rem;
    outline: 0;
}

.modal_header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid var(--cui-modal-header-border-color, #d8dbe0);
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.modal_body {
    overflow: hidden;
    position: relative;
    width: 760px;
    flex: 1 1 auto;
    padding: 1rem;
    overflow-y: auto;
}

.modal_title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal_footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #d8dbe0;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
} 

.modal_close {
    position: absolute;
    right: 40px;
    top: 60px;
    width: 24px;
    height: 24px;
    background: #1C1C21;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    flex: none;
    order: 1;
    flex-grow: 0;
    cursor: pointer;
}

.modal_btn {
    cursor: pointer;
    background-color:  #9da5b1 !important; 
}

.modal_close svg {
    position: absolute;
    top: 1px;
}
