.footer_wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    min-height: 3rem;
    padding: 0.5rem 1rem;
    color: var(--cui-footer-color, rgba(44, 56, 74, 0.95));
    background: var(--cui-footer-bg, #ebedef);
    border-top: var(--cui-footer-border-width, 1px) solid var(--cui-footer-border-color, #d8dbe0);
}